<template>
   	<div class="pubs-container">
		<page-comp
			:publs="preprint"
			:publs-type="publsType"
			:ru-version="ruVersion"
			:t = "t"
			:config="config"
		></page-comp>

		<slider-comp
			:item-list = "related"
			:slider-title = "t ? t.similarPublications : '...'"
			:publs-type = "publsType"
			:publs-id = "publsId"
			:items-cnt = "itemsCnt"
			:show-cover = "showCover"
			:ru-version = "ruVersion"
			:config="config"
		></slider-comp>
   	</div>
</template>

<script>
    import { mapState } from 'vuex';
	import PageComp from './components/pageComp.vue';
	import SliderComp from './components/sliderComp.vue';
	import { mixUtils } from './mixins/mixUtils.js';

    export default {
		name: 'preprint',
		components: {
			'page-comp': PageComp,
			'slider-comp': SliderComp,
		},
		metaInfo() {
			return {
				title: this.preprint && this.preprint.title ? this.preprint.title :'',
				meta: this.preprint && this.preprint.metaTegs ? this.preprint.metaTegs : [],
				lang: this.ruVersion ? 'ru' : 'en',
			}
		},
		props: {
			id: {
				type: String,
				required: false,
				default: '0',
			},
 		},
		mixins: [mixUtils,],
		data: () => ({
			currId: 0,
			publsType: "pPreprint",
			publsId: "preprints",
			itemsCnt: 4,
			showCover: false,
		}),
		computed: mapState ({
			preprint: state => state.preprint,
			config: state => state.config,
			related: state => (state.preprint && state.preprint.related) ? state.preprint.related : [],
			ruVersion: state => state.templateRuVersion,
			t: state => state.templateT,
		}),

		/**
		 * Сервер. Первичное гидрирование
		 */

		serverPrefetch () {
			this.currId = this.id
			this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
			return this.getPreprint(this.config.serverBaseURL);
		},
		/**
		 * Слиент. Доп. загрузка
		 */
		mounted () {
			this.currId = this.id
			if (!this.preprint) {
				this.getPreprint(this.config.clientBaseURL);
			}
		},
		methods: {
			getPreprint(baseURL) {
				return this.$store.dispatch('aLoadPreprint', {params: {"id": this.currId}, baseURL});
			},
		}

    };
</script>
