<template>

    <div :id='`${appN}`' v-show="isClientReady" class="popmechanic-desktop" :data-panel="showBlandPanel" :data-size="dataSize" :data-spacing="dataSpacing" :data-contrast="dataContrast" :data-image="dataImage">
        <template v-if="useTemplate === 1">
            <publs-sv-control
                :sv="sv"
                :ruVersion="ruVersion"
                :config="config"
                :onHandleSV="handleSV"
                :onNormalizeMode="normalizeMode"
            ></publs-sv-control>
            <div class="header header_dark_gradient" @click.stop=deselectAll>
                <div class="crop">
                    <publs-header-top
                        :campus="campus"
                        :origin="origin"
                        :top-hse-name="topHseName"
                        :ruVersion="ruVersion"
                        :t="t"
                        :bread-crumbs="breadCrumbs"
                        :showSearchPanel="showSearchPanel"
                        :config="config"
                        :onOpenBlindPanel="openBlindPanel"
                        :onSeleSearchPanel="seleSearchPanel"
                    ></publs-header-top>
                    <publs-header-board
                        :topMenu="topMenu ? topMenu : []"
                        :config="config"
                    ></publs-header-board>
                </div>
            </div>

            <div class="layout has-sidebar">
                <div class="grid"></div>
                <div class="grid grid_3">
                    <div class="wrapper">
                        <publs-sidebar
                            :ruVersion="ruVersion"
                            :devLine="devLine"
                            :years="years"
                            :researchTarget="researchTarget"
                            :publNews="publNews"
                            :t="t"
                            :config="config"
                        ></publs-sidebar>
                    </div>
                    <div class="main main_with-sidebar row">
                        <div class="content">
                            <div class="content__inner">
                                <div class="pubs-head">
                                    <div v-if="logo" class="pubs-logo">
                                        <a class="link link_dark link_no-underline" style="color: black;" :href="`${logo.href}`">{{t ? t[logo.title] : ''}}</a>
                                    </div>
                                    <div class="pubs-nav js-bookmark">
                                        <ul class="navigation pubs-primary_menu ul">
                                            <li v-for='item in primaryMenu' :key='item.id' :class="item.cls">
                                                <a :href="`${item.href}`" class="link_inherit navigation__link">{{t ? t[item.title] : ''}}</a>
                                            </li>
                                        </ul>
                                        <ul class="navigation pubs-secondary_menu ul">
                                            <li v-for='item in secondaryMenu' :key='item.id' :class="item.cls">
                                                <a v-show="item.showItem" class="link navigation__link" :href="`${item.href}`">{{t && t[item.title] ? t[item.title] : item.title}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <router-view></router-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer class="footer">
                <div class="footer__inner row">
                    <div class="footer__border"></div>
                    <div style="clear:both;"></div>
                    <publs-footer
                        :navigation-footer="navigationFooter"
                        :links="links"
                        :origin="origin"
                        :copyright-footer="copyrightFooter"
                        :t="t"
                        :config="config"
                    ></publs-footer>
                </div>
            </footer>
        </template>
        <template v-if="useTemplate === 2">
            <div class="content_widget b-person-data-pubs publications printable" tab-index="sci" tab-node="pubs">
                <div class="content_widget_inner">
                    <router-view></router-view>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import PublsSidebar from './client/components/publsSidebar.vue';
    import PublsFooter from './client/components/publsFooter.vue';
    import PublsSVControl from './client/components/publsSVControl.vue';
    import PublsHeaderTop from './client/components/publsHeaderTop.vue';
    import PublsHeaderBoard from './client/components/publsHeaderBoard.vue';
    import { mixUtils } from '../src/client/mixins/mixUtils.js';
    export default {
        name: 'App',
        mixins: [ mixUtils,],
		data: () => ({
            isClientReady: false,
            showSearchPanel: false,
            showBlandPanel: 'off',
            dataSize: undefined,
            dataSpacing: undefined,
            dataContrast: undefined,
            dataImage: undefined,
            appN:'app',
		}),
        components: {
            'publs-sidebar': PublsSidebar,
            'publs-footer': PublsFooter,
            'publs-sv-control': PublsSVControl,
            'publs-header-top': PublsHeaderTop,
            'publs-header-board': PublsHeaderBoard,
        },
        computed: mapState ({
			ruVersion: state => state.templateRuVersion,
			origin: state => state.templateOrigin,
			campus: state => state.templateCampus,
			breadCrumbs: state => state.templateBreadCrumbs || [],
			navigationFooter: state => state.templateNavigationFooter,
			links: state => state.templateLinks,
			topHseName: state => state.templateTopHseName,
			copyrightFooter: state => state.templateCopyrightFooter,
			t: state => state.templateT,
            topMenu: state => state.templateTopMenu || [],
            // sidebar
            devLine: state => state.templateDevLine || [],
            years: state => state.templateYears || [],
            researchTarget: state => state.templateResearchTarget || [],
            publNews: state => state.templateNews || [],

            logo: state => state.templateLogo || {},
            primaryMenu: state => state.templatePrimaryMenu || [],
            secondaryMenu: state => state.templateSecondaryMenu || [],
            sv: state => state.templateSV ||{},
            config: state => state.config,
            useTemplate: state => state.useTemplate,
		}),
        methods: {
            getTemplateData(baseURL, lang) {
                const params = {
                    lang,
                    selected: {}
                }
                // Тематики/Рубрики
                if (this.$router && this.$router.currentRoute && this.$router.currentRoute.path.split('/').includes('rubric')) {
                    params.selected = {
                        ...{selectedRublic: this.$router.currentRoute.params.id.replace(/\D+/g,"")}
                    };
                }
                return this.$store.dispatch('aLoadTemplateData', {params, baseURL});
            },
            normalizeMode(){
                // this.showSearchPanel = false;
                this.showBlandPanel = this.showBlandPanel === 'on' ? 'off' : 'on';
                this.dataSize=undefined;
                this.dataSpacing=undefined;
                this.dataContrast=undefined;
                this.dataImage=undefined;
            },
            handleSV(sv){
                // console.log(`>>> sv: ${JSON.stringify(sv, null, 4)}`)
                this.$store.dispatch('aActivateSV', sv);
                if(sv.dataType ==="size") {
                    this.dataSize = sv.dataValue === 'normal' ? undefined : sv.dataValue;
                } else if(sv.dataType==='spacing'){
                    this.dataSpacing = sv.dataValue === 'normal' ? undefined : sv.dataValue;
                } else if(sv.dataType==='contrast'){
                    this.dataContrast = sv.dataValue === 'normal' ? undefined : sv.dataValue;
                } else if(sv.dataType==='image'){
                    this.dataImage = sv.dataValue === 'on' ? undefined : sv.dataValue;
                }
            },
            seleSearchPanel(){
                this.showBlandPanel = false;
                this.showSearchPanel = true;
            },
            openBlindPanel(){
                this.showSearchPanel = false;
                this.showBlandPanel = this.showBlandPanel === 'on' ? 'off' : 'on';
            },
            deselectAll(){
                this.showSearchPanel = false
                this.showBlandPanel = 'off';
            },
            // пока отложим
            // appIncrementor(){
            //     let fw = 'app';
            //     let i = 1;
            //     while (true) {
            //         const el = document && document.getElementById(fw);
            //         if(!el) {
            //             this.appN = fw;
            //             break;
            //         }
            //         fw = `app_${i++}`;
            //     }
            // }
        },
        serverPrefetch () {
            this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
            this.$store.dispatch('aReLoadOrigin', {origin: this.$ssrContext.origin});
            this.$store.dispatch('aReLoadBreadCrumbs', {breadCrumbs: this.$ssrContext.breadCrumbs});
            if (this.useTemplate === 1) {
                return this.getTemplateData(this.config.serverBaseURL, this.$ssrContext.lang);
            }
        },
        mounted () {
            // config для виджетов
            if(document && !this.useTemplate){
                let pn = '/'
                try {
                    pn = window != undefined && window.location && window.location.pathname || null;
                } catch(e) {
                }
                const config = {
                    pubsUnit: document.currentScript.dataset?.orgunitId || 0,  // 120026365
                    author: document.currentScript.dataset?.author, //65156
                    widgetName: document.currentScript.dataset.widgetName, // 'Search'|'PubsMarked'|'AuthorSearch'
                    widgetPrefixURL: document.currentScript.dataset.widgetPrefixUrl || pn,
                    lang: document.currentScript.dataset.lang || 'ru',
                    wotTemplate: true,
                    prefixURL: document.currentScript.dataset.prefixUrl, //'/org/hse/publications/'
                    clientBaseURL: document.currentScript.dataset.clientBaseUrl, //'https://p1.hse.ru'
                    hseURL: document.currentScript.dataset.hseURL || 'https://www.hse.ru',
                    publicationsURL: document.currentScript.dataset.pubsURL || 'https://publications.hse.ru',
                    publsSort: 'TITLE_DESC',
                }
                // console.log(`>>> config: ${JSON.stringify(config, '', 4)}`)
                this.$store.dispatch('aLoadConfig', {config}); // только для widgets
                this.appN = config.pubsUnit ? `app_${config.pubsUnit}` : 'app';
            }
			if (!this.devLine && this.useTemplate === 1) {
                // console.log(`>>> App mounted: ${JSON.stringify(this.config)}`)
                this.getTemplateData(this.config.clientBaseURL, this.config.lang);
            }
            this.isClientReady = true;
        }
    }

</script>
