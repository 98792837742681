<template>
	<div class="pubs-search-criteria">
		<template v-for='category in searchCategory'  >
			<div v-if="category.show === false && shadowCriteria.filter(el => el == category.id).length == 0" :key='category.id' :class="`pubs-search__box box-criteria cat_id_${category.id}`">

				<div class="pubs-search__box_btn" @click="deSelectCategiry(category)"></div>
				<div class="pubs-search__category">{{tt(category.title)}}:</div>

				<template v-if="category.type ==='author'">
					<div class="pubs-search__item box-criteria__item">
						<ssr-input-auto
							:debounce = 100
							placeholder = '...'
							inputClass = 'pubs-search__input'
							containerInputClass = 'input-container'
							:onItemSelected="selectedAuthor"
							:onSearch="searchAuthor"
							><div slot="item" slot-scope="authors" class="ui-menu-item">
								<a class="ui-corner-all">{{authors.item.title}}</a>
							</div>
						></ssr-input-auto>

						<div v-for='(itm, index) in authorList' :key="itm.id" class="selected-single-item">
							<div class="pubs-search__tag--temporary autor-items">
								<span class="pubs-search__close" @click="deleteAuthor(index)"></span>
								<span class="author-title">{{itm.title}}</span>
							</div>
						</div>
					</div>
					<!-- <div class="pubs-search__item">
						<div class="input-text-container">
							<input v-model="searchCategory[0].value" :class="`pubs-search__input ${category.type}`" type="text" @change="changeInput">
						</div>
					</div> -->
				</template>

				<template v-if="category.type ==='campus'">
					<div v-for='campus in btnCampus' :key="campus.id" class="pubs-search__item box-criteria__item">
						<input
							type="radio"
							class="pubs-search__tag"
							:id="`pubs-search__item__campus__${campus.id}`"
							:value="`${campus.value}`"
							name="campus"
							@change="changeInput"
							v-model="searchCategory[1].value"
						>
						<label :for="`pubs-search__item__campus__${campus.id}`" class="pubs-search__item-label">{{tt(campus.label)}}</label>
					</div>
				</template>
				<!-- :checkboxLabel = "tt('recursiveSearch')" 
					checkboxContainerClass = 'pubs-search__item'
					:defaultCkecked = false
				-->

				<template v-if="category.type ==='unit'">
					<div class="pubs-search__item box-criteria__item">
						<ssr-input-auto
							:debounce = 100
							placeholder = '...'
							inputClass = 'pubs-search__input'
							containerInputClass = 'input-container'
							:checkboxLabel = 'none'
							checkboxContainerClass = 'pubs-search__item checkbox_unit'
							:defaultCkecked = true
							:onItemSelected="selectedUnit"
							:onSearch="searchUnits"
							><div slot="item" slot-scope="units1" class="ui-menu-item">
								<a class="ui-corner-all">{{units1.item.title}}
									<span class="ui-item-desc">{{units1.item.descript}}</span>
								</a>
							</div>
						></ssr-input-auto>

						<div v-for='(unt, index) in unitList' :key="unt.id" class="selected-unit-item">
							<div class="pubs-search__tag--temporary">
								<span class="pubs-search__close" @click="deleteUnit(index)"></span>
								<span class="unit-title">{{unt.title}}</span>
								<span class="unit-desc">{{unt.descript}}</span>
							</div>
						</div>
					</div>
				</template>
				<template v-if="category.type ==='keyword'">
					<div class="pubs-search__item box-criteria__item">
						<ssr-input-auto
							:debounce = 100 
							placeholder = '...'
							inputClass = 'pubs-search__input'
							containerInputClass = 'input-container'
							:onItemSelected="selectedKeyword"
							:onSearch="searchKeyword"
							><div slot="item" slot-scope="keywords" class="ui-menu-item">
								<a class="ui-corner-all">{{keywords.item.title}}</a>
							</div>
						></ssr-input-auto>

						<div v-for='(itm, index) in keywordList' :key="itm.id" class="selected-single-item">
							<div class="pubs-search__tag--temporary keyword-items">
								<span class="pubs-search__close" @click="deleteKeyword(index)"></span>
								<span class="keyword-title">{{itm.title}}</span>
							</div>
						</div>
					</div>
				</template>
				<template v-if="category.type ==='researchTarget'">
					<div class="pubs-search__item box-criteria__item">
						<ssr-input-auto
							:debounce = 100 
							placeholder = '...'
							inputClass = 'pubs-search__input'
							containerInputClass = 'input-container'
							:onItemSelected="selectedResearchTarget"
							:onSearch="searchResearchTarget"
							><div slot="item" slot-scope="researchTarget" class="ui-menu-item">
								<a class="ui-corner-all">{{researchTarget.item.title}}</a>
							</div>
						></ssr-input-auto>

						<div v-for='(itm, index) in researchTargetList' :key="itm.id" class="selected-single-item">
							<div class="pubs-search__tag--temporary research-target-items">
								<span class="pubs-search__close" @click="deleteResearchTarget(index)"></span>
								<span class="research-target-title">{{itm.title}}</span>
							</div>
						</div>
					</div>
				</template>
				<template v-if="category.type ==='devLine'">
					<div class="pubs-search__item box-criteria__item">
						<ssr-input-auto
							:debounce = 100
							placeholder = '...'
							inputClass = 'pubs-search__input'
							containerInputClass = 'input-container'
							:onItemSelected="selectedDevLine"
							:onSearch="searchDevLine"
							><div slot="item" slot-scope="devLine" class="ui-menu-item">
								<a class="ui-corner-all">{{devLine.item.title}}</a>
							</div>
						></ssr-input-auto>

						<div v-for='(itm, index) in devLineList' :key="itm.id" class="selected-single-item">
							<div class="pubs-search__tag--temporary dev-line-items">
								<span class="pubs-search__close" @click="deleteDevLine(index)"></span>
								<span class="dev-line-title">{{itm.title}}</span>
							</div>
						</div>
					</div>
				</template>
				<template v-if="category.type ==='language'">
					<div class="pubs-search__item box-criteria__item">
						<ssr-input-auto
							:debounce = 100
							placeholder = '...'
							inputClass = 'pubs-search__input'
							containerInputClass = 'input-container'
							:onItemSelected="selectedLanguage"
							:onSearch="searchLanguage"
							><div slot="item" slot-scope="Language" class="ui-menu-item">
								<a class="ui-corner-all">{{Language.item.title}}</a>
							</div> 
						></ssr-input-auto>
						<div class="pubs-search__default_item">
							<span class="pubs-search__label link white" @click="setDefaultLanguage({id: 20591, title:'русский'})">русский</span>&nbsp;
							<span class="pubs-search__label link white" @click="setDefaultLanguage({id: 20592, title:'английский'})">английский</span>
						</div>
						<div v-for='(itm, index) in languageList' :key="itm.id" class="selected-single-item">
							<div class="pubs-search__tag--temporary language-items">
								<span class="pubs-search__close" @click="deleteLanguage(index)"></span>
								<span class="language-title">{{itm.title}}</span>
							</div>
						</div>
					</div>
				</template>
				<template v-if="category.type ==='year'">
					<div class="pubs-search__item  year-slider box-criteria__item">
						<div class="year-min-value">{{ period[0] }}</div>
						<div class="year-max-value">{{ period[1] }}</div>
						<vue-slider
							class="year-range"
							ref="slider"
							v-model="period"
							:min="maxMinYears[0]"
							:max="maxMinYears[1]"
							:enable-cross="false"
							:height="5"
							:process-dragable="true"
							:tooltip="'active'"
							:tooltip-placement="['bottom', 'bottom']"
							:tooltip-formatter="'{value}г.'"
							:use-keyboard="true"
							@change="changeYears"
						></vue-slider>
					</div>
				</template>
			</div>
		</template>


		<div class="pubs-search__box no-serialize js-search_box js-active u-ta_r">
			<div class="pubs-search__popup_box">
				<span @click="onShowPopup" class="popup_control">{{!showPopup ? tt('addFilter') : (ruVersion ? 'Закрыть список' : 'Close list')}}<span :class="`pubs-search__box_btn ${!showPopup ? 'pubs-search__box_btn--add' : ''}`"></span></span>
				<div v-show="showPopup" class="pubs-search__popup">
					<ul class="navigation">
						<li
							v-for='category in searchCategory' :key='category.id'
							:class="`popup_category ${category.type}`" 
							v-show="category.show"
							@click="selectCategory(category)"
						>{{tt(category.title)}}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import Vue from 'vue'
	import axios from 'axios'
	import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
	import SsrInputAuto from './ssrInputAuto.vue'
	import { mixUtils } from '../mixins/mixUtils.js';

    export default {
		name: 'DynamicCriteriaComp',
		components: {
			SsrInputAuto,
			VueSlider
		},
        props: {
			ruVersion: {
				type: Boolean,
				required: true,
				derault: true,
			},
			t: {
				type: Object,
				required: false,
				default: () => {},
			},
			queryParams: {
				type: Array,
				required: false,
				default: () => [],
			},
			config: {
                type: Object,
                required: false,
                default: () => {},
			},

			onSelectCategory: {
				type: Function,
				required: true,
			},
		},
		mixins: [mixUtils,],
		data: () => ({
			searchCategory: [
				{id: 1, type: 'author', title: 'author', show: true, value: null, defaultValue: null},
				{id: 2, type: 'campus', title: 'campus', show: true, value: '0', defaultValue: '0'},
				{id: 3, type: 'unit', title: 'subDepartment', show: true, value: null, defaultValue: null, checked: false},
				{id: 4, type: 'keyword', title: 'keywords', show: true, value: null, defaultValue: null},
				{id: 5, type: 'researchTarget', title: 'researchTarget', show: true, value: null, defaultValue: null},
				{id: 6, type: 'devLine', title: 'priorityAreas', show: true, value: null, defaultValue: null},
				{id: 7, type: 'language', title: 'language', show: true, value: null, defaultValue: null},
				{id: 8, type: 'year', title: 'year', show: true, value: [], defaultValue: [0,0]},
				{id: 10, type: 'pb', title: 'publisher', show: false, value: '0', defaultValue: '0'},
				{id: 11, type: 'mg', title: 'magazine', show: false, value:'0', defaultValue: '0'},
				{id: 12, type: 'sr', title: 'orgseries', show: false, value: '0', defaultValue: '0'},
			],
			showPopup: false,
			btnCampus: [
				{id: 0, value: '22723,135083,135213,135288', label: 'allCampuses'},
				{id: 1, value: '22723', label: 'moscow'},
				{id: 2, value: '135083', label: 'spb'},
				{id: 3, value: '135288', label: 'nnov'},
				{id: 4, value: '135213', label: 'perm'},
			],
			authorList: [],
			campysType: '0',
			unitList: [],
			keywordList: [],
			researchTargetList: [],
			devLineList: [],
			languageList: [],
			maxMinYears: [],
			period: [0,0],
			shadowCriteria: [10,11,12], // Критерии не участвующие в выборе
			canChange: true,
			changeSearchHash: true, // хеш поиска передавать в ссылке?
		}),
		watch: {
			queryParams: function (newQueryParams) {
				if(newQueryParams){
					this.changeSearchHash = false;
					this.queryParamsSetting(newQueryParams);
				}
			}
		},
		methods: {
			async changeInput () {
				if (this.canChange && this.onSelectCategory) {
					await this.onSelectCategory(this.searchCategory, this.changeSearchHash);
					this.changeSearchHash = true;
				}
			},
			selectCategory (category, seed = true) {
				if (!category) {
					return
				}
				for (let cat of this.searchCategory) {
					if (cat.id === category.id) {
						cat.show = false
						break
					}
				}
				this.showPopup = false;
				if (category.id === 8 ) {
					this.period = [this.maxMinYears[0], this.maxMinYears[1]]
					this.searchCategory[7].value = this.period;
				}
				this.changeInput();
			},
			deSelectCategiry (category) {
				if (!category) {
					return
				}
				for (const cat of this.searchCategory) {

					if (cat.id === category.id) {
						cat.show = true;
						cat.value = cat.defaultValue;
						switch (category.type) {
							case 'unit': this.unitList.length = 0;
								break;
							case 'keyword':  this.keywordList.length = 0;
								break;
							case 'researchTarget': this.researchTargetList.length = 0;
								break;
							case 'devLine': this.devLineList.length = 0;
								break;
							case 'language': this.languageList.length = 0;
								break;
							case 'author': this.authorList.length = 0;
								break;
						}
						break
					}
				}
				this.showPopup = false;
				this.changeInput();
				this.period = [this.maxMinYears[0], this.maxMinYears[1]];
			},
			/**
			 *  Возможно ли отображение popup
			 */
			onShowPopup () {
				this.showPopup = this.showPopup ? false : this.searchCategory.some(category => category.show);
			},

			// Категории поиска предприятий
			async searchUnits(searchContext, status) {
				if (searchContext.length < 3) {
					return
				}
				const params = {
					searchContext, 
					findInChildren: status,
					lang: this.ruVersion ? 'ru' : 'en',
				}
				return await axios.post(`${this.getPrefixURL()}api/getUnits`, params).then(r => {
					const items = [];
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.id) {
								items.push({ id: item.id, title: item.label, descript: item.description });
							}
						}
					}
					return items;
				})
			},
			// Поиск предприятия по ID
			async searchUnit(id, status) {
				if (!id) {
					return
				}
				const params = {
					id, 
					lang: this.ruVersion ? 'ru' : 'en',
				}
				return await axios.post(`${this.getPrefixURL()}api/getUnit`, params).then(r => {
					const items = [];
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.id) {
								items.push({ id: item.id, title: item.label, descript: item.description });
							}
						}
					}
					return items;
				})
			},

			selectedUnit(selectedItem, checkboxStatus) {
				if (this.unitList.filter(itm => itm.id == selectedItem.id).length === 0) {
					this.unitList.push(selectedItem);
				}
				this.searchCategory[2].value = this.unitList;
				this.searchCategory[2].checked = checkboxStatus;
				this.changeInput()
			},
			deleteUnit(index) {
				this.unitList.splice(index,1);
			},
			// -------------------------------------
			async searchAuthor(searchContext) {
				if (searchContext.length < 3) {
					return
				}
				const params = {
					searchContext,
					ruVersion: this.ruVersion,
				}
				return await axios.post(`${this.getPrefixURL()}api/getAuthors`, params).then(r => {
					const items = []
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.title) {
								items.push(item)
							}
						}
					}
					return items
				})
			},

			selectedAuthor(selectedItem) {
				if (this.authorList.filter(itm => itm.id == selectedItem.id).length === 0) {
					this.authorList.push(selectedItem);
				}
				this.searchCategory[0].value = this.authorList;
				this.searchCategory[0].show = this.authorList.length === 0;
				this.changeInput()
			},
			deleteAuthor(index) {
				this.authorList.splice(index,1);
			},
			// -------------------------------------
			async searchKeyword(searchContext, status) {
				if (searchContext.length < 3) {
					return
				}
				const params = {
					searchContext,
					ruVersion: this.ruVersion,
				}
				return await axios.post(`${this.getPrefixURL()}api/getKeywords`, params).then(r => {
					const items = []
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.title) {
								items.push(item)
							}
						}
					}
					return items
				})
			},

			selectedKeyword(selectedItem) {
				if (this.keywordList.filter(itm => itm.id == selectedItem.id).length === 0) {
					this.keywordList.push(selectedItem);
				}
				this.searchCategory[3].value = this.keywordList;
				this.changeInput()
			},
			deleteKeyword(index) {
				this.keywordList.splice(index,1);
			},
			// -------------------------------------
			async searchResearchTarget(searchContext, withFilter = false) {
				if (searchContext.length < 3) {
					return
				}
				const params = {
					searchContext, 
					flatList: true,
					ruVersion: this.ruVersion,
				}
				return await axios.post(`${this.getPrefixURL()}api/getResearchTarget`, params).then(r => {
					const items = []
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.title) {
								items.push(item)
								if (item.children && item .children.length) {
									for (const child of item.children) {
										items.push(child)
									}
								}
							}
						}
					}
					return withFilter ? items.filter(itm => itm.title.indexOf(searchContext) >= 0 ) : items;
				})
			},

			selectedResearchTarget(selectedItem) {
				if (this.researchTargetList.filter(itm => itm.id == selectedItem.id).length === 0) {
					this.researchTargetList.push(selectedItem);
				}
				this.searchCategory[4].value = this.researchTargetList;
				this.changeInput()
			},
			deleteResearchTarget(index) {
				this.researchTargetList.splice(index,1);
			},
			// -------------------------------------
			async searchDevLine(searchContext, status) {
				const params = {
					searchContext, 
					ruVersion: this.ruVersion,
				}

				return await axios.post(`${this.getPrefixURL()}api/getDevline`, params).then(r => {
					const items = []
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.title) {
								items.push(item)
							}
						}
					}
					return items
				})
			},

			selectedDevLine(selectedItem) {
				if (this.devLineList.filter(itm => itm.id == selectedItem.id).length === 0) {
					this.devLineList.push(selectedItem);
				}
				this.searchCategory[5].value = this.devLineList;
				this.changeInput()
			},
			deleteDevLine(index) {
				this.devLineList.splice(index,1);
			},
			// -------------------------------------
			async searchLanguage(searchContext, status) {
				const params = {
					searchContext, 
					ruVersion: this.ruVersion,
				}

				return await axios.post(`${this.getPrefixURL()}api/getLanguage`, params).then(r => {
					const items = []
					if (r.data.status == 'ok') {
						for (const item of r.data.result) {
							if (item.title) {
								items.push(item)
							}
						}
					}
					return items
				})
			},

			selectedLanguage(selectedItem) {
				if (this.languageList.filter(itm => itm.id == selectedItem.id).length === 0) {
					this.languageList.push(selectedItem);
				}
				this.searchCategory[6].value = this.languageList;
				this.searchCategory[6].show = this.languageList.length === 0;
				this.changeInput()
			},
			deleteLanguage(index) {
				this.languageList.splice(index,1);
			},

			setDefaultLanguage({id, title}) {
				if (id && title && this.languageList.filter(itm => itm.id == id).length === 0) {
					this.languageList.push({id, title})
				}
				this.searchCategory[6].value = this.languageList;
				this.changeInput()
			},

			// -------------------------------------
			async getYears() {
				const params = {
					ruVersion: this.ruVersion,
				}
				return await axios.post(`${this.getPrefixURL()}api/getYears`, params).then(r => {
					if (r.data.status == 'ok') {
						this.maxMinYears.length = 0;
						this.maxMinYears.push(r.data.result[r.data.result.length-1]);
						this.maxMinYears.push(r.data.result[0]);
					}
				})
			},
			changeYears() {
				this.searchCategory[7].value = this.period;
				this.changeInput()
			},
			/**
			 * qp = {"type":"dl|year|rubric","id":"66508908 | 2020","title":""}
			 */
			async queryParamsSetting(queryParameters) {
					this.canChange = false;
				// console.log(`>>> 0.1 savedParams -> queryParameters: ${JSON.stringify(queryParameters, '', 4)}`)
				for (const qp of queryParameters) {
					if (qp && qp.type === 'dl') {
						const foundSet = await this.searchDevLine(qp.id);
						if (foundSet && foundSet.length) {
							const item = foundSet.filter(itm => itm.id == qp.id);
							if (item[0] && item[0].title) {
								qp.title = item[0].title;
								await this.selectedDevLine(qp);
							}
							this.selectCategory({id: 6})
						}
					} else if (qp && qp.type === 'kw') {
						qp.type = 'Keyword'
						const foundSet = await this.searchKeyword(qp.id, true);
						if (foundSet && foundSet.length) {
							const item = foundSet.filter(itm => itm.id == qp.id);
							if (item[0] && item[0].title) {
								await this.selectedKeyword({
									type: 'keyword',
									id: qp.id,
									title: item[0].title,
								});
								this.selectCategory({id: 4})
							}
						}
					} else if (qp && qp.type === 'lang') {
						qp.type = 'language'
						this.languageList.length = 0;
						const foundSet = await this.searchLanguage(qp.title, true);
						if (foundSet && foundSet.length) {
							const item = foundSet.filter(itm => itm.title == qp.title);
							if (item[0] && item[0].title) {
								await this.selectedLanguage({
									type: 'language',
									id: item[0].id,
									title: item[0].title,
								});
								this.selectCategory({id: 7}, false);
							}
						}
					} else if (qp && qp.type === 'year') {
						if (typeof(qp.id) === 'object') {
							this.period = [qp.id.min, qp.id.max];
						} else {
							this.period = [Number(qp.id), Number(qp.id)];
							this.maxMinYears = [Number(qp.id), Number(qp.id)];
						}
						this.searchCategory[7].show = false;
						this.searchCategory[7].value = this.period;
					} else if (qp && qp.type === 'rubric') {
						const foundSet = await this.searchResearchTarget(qp, false);
						if (foundSet && foundSet.length) {
							const item = foundSet.filter(itm => itm.id == Number(qp.id.replace(/\D+/g,"")));
							if (item[0] && item[0].title) {
								qp.title = item[0].title;
								await this.selectedResearchTarget(qp);
								this.selectCategory({id: 5})
							}
						}
					} else if (qp && qp.type === 'ou') {
						this.unitList.length = 0;
						const foundSet = await this.searchUnit(qp.id, true);
						if (foundSet && foundSet.length) {
							const item = foundSet.filter(itm => itm.id == qp.id);
							if (item[0] && item[0].title) {
								await this.selectedUnit({
									type: 'unit',
									id: qp.id,
									title: item[0].title,
									descript: item[0].descript,
								}, false);
								this.selectCategory({id: 3});
							}
						}
					} else if (qp && qp.type === 'unit') {
						this.unitList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const unitTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchUnits(unitTitle, true);
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedUnit({
										type: 'unit',
										id: qp.id[index],
										title: item[0].title,
										descript: item[0].descript,
									}, false);
									this.selectCategory({id: 3});
								}
							}
						}
					} else if (qp && qp.type === 'childDeparts') {
						this.unitList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const unitTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchUnits(unitTitle, true);
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedUnit({
										type: 'unit',
										id: qp.id[index],
										title: item[0].title,
										descript: item[0].descript,
									}, true);
									this.selectCategory({id: 3});
								}
							}
						}
					} else if (qp && qp.type === 'keyword') {
						this.keywordList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const keywordTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchKeyword(keywordTitle, true);
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedKeyword({
										type: 'keyword',
										id: qp.id[index],
										title: item[0].title,
									});
									this.selectCategory({id: 4});
								}
							}
						}
					} else if (qp && qp.type === 'author') {
						this.authorList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const authorTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchAuthor(authorTitle, true);
							// console.log(`>>> Author 710: ${JSON.stringify(foundSet)}`)
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedAuthor({
										type: 'author',
										id: qp.id[index],
										title: item[0].title,
									});
									this.selectCategory({id: 1});
								}
							}
						}
					} else if (qp && qp.type === 'devLine') {
						this.devLineList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const devlineTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchDevLine(devlineTitle, true);
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedDevLine({
										type: 'devLine',
										id: qp.id[index],
										title: item[0].title,
									});
									this.selectCategory({id: 6});
								}
							}
						}
					} else if (qp && qp.type === 'language') {
						this.languageList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const languageTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchLanguage(languageTitle, true);
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedLanguage({
										type: 'language',
										id: qp.id[index],
										title: item[0].title,
									});
									this.selectCategory({id: 7});
								}
							}
						}
					} else if (qp && qp.type === 'researchTarget') {
						this.researchTargetList.length = 0;
						for (let index = 0; index < qp.title.length; index++) {
							const researchTargetTitle = qp.title[index].replace(/\"/g, '\\"')
							const foundSet = await this.searchResearchTarget(researchTargetTitle, true);
							if (foundSet && foundSet.length) {
								const item = foundSet.filter(itm => itm.id == qp.id[index]);
								if (item[0] && item[0].title) {
									await this.selectedResearchTarget({
										type: 'researchTarget',
										id: qp.id[index],
										title: item[0].title,
									});
									this.selectCategory({id: 5});
								}
							}
						}
					} else if (qp && qp.type === 'campus') {
						this.searchCategory[1].value = Array.isArray(qp.id) ? qp.id.sort((v1,v2)=> v1 < v2 ? -1 : 1).join(',') : qp.id
						this.searchCategory[1].show = false;
					} else if (qp && qp.type === 'pb') {
						this.searchCategory[8].value = qp.id;
					} else if (qp && qp.type === 'mg') {
						this.searchCategory[9].value = qp.id;
					} else if (qp && qp.type === 'sr') {
						this.searchCategory[10].value = qp.id;

					}
				}

				this.canChange = true;
				await this.changeInput();

			}
		},
		async mounted() {
			await this.getYears();
		}
    };
</script>
