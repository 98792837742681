<template>
   	<div class="pubs-container">
		<page-comp
			:publs="article"
			:publs-type="publsType"
			:ru-version = "ruVersion"
			:t = "t"
			:config="config"
		></page-comp>

		<slider-comp
			:item-list = "related"
			:slider-title = "t ? t.similarPublications : '...'"
			:publs-type = "publsType"
			:publs-id = "publsId"
			:items-cnt = "itemsCnt"
			:show-cover = "showCover"
			:ru-version = "ruVersion"
			:config="config"
		></slider-comp>
   	</div>
</template>

<script>
   	import { mapState } from 'vuex';
	import PageComp from './components/pageComp.vue';
	import SliderComp from './components/sliderComp.vue';
	import { mixUtils } from './mixins/mixUtils.js';

    export default {
		name: 'Article',
		components: {
			'page-comp': PageComp,
			'slider-comp': SliderComp,
		},
		metaInfo() {
			return {
				title: this.article && this.article.title ? this.article.title : '',
				lang: this.ruVersion ? 'ru' : 'en',
				meta: this.article && this.article.metaTegs ? this.article.metaTegs : [],
			}
		},
		props: {
			id: {
				type: String,
				required: false,
				default: '0',
			},
        },
		mixins: [mixUtils,],
		data: () => ({
			currId: 0,
			publsType: "pArticle",
			publsId: "articles",
			itemsCnt: 4,
			showCover: false,
		}),
		computed: mapState ({
			article: state => state.article,
			config: state => state.config,
			related: state => (state.article && state.article.related) ? state.article.related : [],
			ruVersion: state => state.templateRuVersion,
			t: state => state.templateT,
			origin: state => state.templateOrigin
		}),
		methods: {
			getArticle(baseURL) {
				return this.$store.dispatch('aLoadArticle', {params: {"id": this.currId}, baseURL});
			},
		},
		/**
		 * Сервер. Первичное гидрирование
		 */
		serverPrefetch () {
			this.currId = this.id
			this.$store.dispatch('aLoadConfig', {config: this.$ssrContext.config});
			return this.getArticle(this.config.serverBaseURL);
		},
		/**
		 * Слиент. Доп. загрузка
		 */
		mounted () {
			this.currId = this.id
			if (!this.article) {
				this.getArticle(this.config.clientBaseURL);
			}
		},

    };
</script>
