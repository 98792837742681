import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import sanitizeHtml from 'sanitize-html';
import "regenerator-runtime/runtime.js";

Vue.use(Vuex)

const createStore = () => {
	const axi = axios.create();

	const clearHTML = (val) => {
		if (!val)
			return '';
		const options = {
			...sanitizeHtml.defaults,
			allowedTags: [
				...sanitizeHtml.defaults.allowedTags,
				'img'
			]
		};
		if (!/\S/.test(val)) return '';
		const retVal = sanitizeHtml(val, options)
			.replace(/\<div.*?\>\&nbsp;\<\/div\>/gm, '')
			.replace(/^\s+|\s+$/gm, '')
			.replace(/[\s\n\r]+$/g, '')
			.replace(/<p> <\/p>/g, '')
			.replace(/<p>&nbsp;<\/p>/g, '')
			.replace(/<p class="text">&nbsp;<\/p>/g, '')
			.replace(/Enable Ginger<em>Cannot connect to Ginger<\/em> Check your internet connection<br \/>/g, '')
			.replace(/or reload the browserDisable in this text fieldRephraseRephrase current sentenceLog in to edit with Ginger×<iframe><\/iframe>/g, '');

		return retVal;
	};


	const Store = new Vuex.Store({
		state: () => ({
			config: undefined,
			useTemplate: 0,
			// lang: undefined,

			book: undefined,
			article: undefined,
			chapter: undefined,
			preprint: undefined,
			copyrightRule: undefined,

			bookList: undefined,
			articleList: undefined,
			chapterList: undefined,
			preprintList: undefined,

			templateRuVersion: true,
			templateOrigin: undefined,
			templateCampus: undefined,
			templateBreadCrumbs: undefined,
			templateNavigationFooter: undefined,
			templateLinks: undefined,
			templateTopHseName: undefined,
			templateCopyrightFooter: undefined,
			templateT: undefined,
			templateTopMenu: undefined,

			templateDevLine: undefined,
			templateYears: undefined,
			templateResearchTarget: undefined,
			templateNews: undefined,

			templateLogo: undefined,
			templatePrimaryMenu: undefined,
			templateSecondaryMenu: undefined,
			templateSV: undefined,
			
			// search
			itemList: undefined,
			totalFoundPubls: undefined,
			cachKey: undefined,
			totalFoundPages: undefined,
			pubsPersonGroupType: undefined,
			pubsPersonSort: undefined,
			admComment: undefined,
			
			urlById: undefined,
			widgetOperParams: undefined,

			error: undefined,
		}),
		actions: {
			async aLoadBook({commit, state}, {params, baseURL} ) {
				axi.defaults.baseURL = baseURL
				await axi.post(`${state.config.prefixURL}api/book`, params)
				.then((res) => {
					if (res.data.status == 'ok') {
						commit('mLoadBook', res.data.result)
					}
				})
				.catch(err => {
					commit('mError', [err, 'aLoadBook'])
				})
			},
			async aLoadArticle({commit, state}, {params, baseURL}) {
				axi.defaults.baseURL = baseURL
				await axi.post(`${state.config.prefixURL}api/article`, params)
				.then((res) => {
					if (res.data.status == 'ok') {
						commit('mLoadArticle', res.data.result)
					}
				})
				.catch(err => {
					commit('mError', [err, 'aLoadArticle'])
				})
			},
			async aLoadChapter({commit, state}, {params, baseURL}) {
				axi.defaults.baseURL = baseURL
				await axi.post(`${state.config.prefixURL}api/chapter`, params)
				.then((res) => {
					if (res.data.status == 'ok') {
						commit('mLoadChapter', res.data.result)
					}
				})
				.catch(err => {
					commit('mError', [err, 'aLoadChapter'])
				})
			},
			async aLoadPreprint({commit, state}, {params, baseURL}) {
				axi.defaults.baseURL = baseURL
				await axi.post(`${state.config.prefixURL}api/preprint`, params)
				.then((res) => {
					if (res.data.status == 'ok') {
						commit('mLoadPreprint', res.data.result)
					}
				})
				.catch(err => {
					commit('mError', [err, 'aLoadPreprint'])
				})
			},
			aLoadPublsList({commit, state}, {params, baseURL}) {
				axi.defaults.baseURL = baseURL;
				return axi.post(`${state.config.prefixURL}api/publs-list`, params)
					.then((res) => {
						if (res.data.status == 'ok') {
							commit('mLoadPublsList', res.data.result)
						}
					})
					.catch(err => {
						commit('mError', [err, 'aLoadPublsList'])
					})
			},

			aLoadTemplateData({commit, state}, {params, baseURL}) {
				axi.defaults.baseURL = baseURL
				return axi.post(`${state.config.prefixURL}api/get-template-data`, params)
					.then((res) => {
						if (res.data.status == 'ok') {
							commit('mLoadTemplateData', [res.data.result, params.selected]);
						} else {
							commit('mError', [res.data.result, 'aLoadTemplateData']);
						}
					})
					.catch(err => {
						commit('mError', [err, 'aLoadTemplateData']);
					})
			},
		    aLoadCopyrightRuleData({commit, state}, {params, baseURL}) {
				axi.defaults.baseURL = baseURL
				return axi.post(`${state.config.prefixURL}api/copyright`, params)
					.then((res) => {
						if (res.data.status == 'ok') {
							commit('mLoadCopyrightRuleData', res.data.result);
						} else {
							commit('mError', [res.data.result, 'aLoadCopyrightRuleData']);
						}
					})
					.catch(err => {
						commit('mError', [err, 'aLoadCopyrightRuleData']);
					})
			},
			aChangeSelectPrimaryMenu({commit}, idSelect){
				commit('mChangeSelectPrimaryMenu', idSelect);
			},
			aChangeSelectSecongaryMenu({commit}, idSelect){
				commit('mChangeSelectSecongaryMenu', idSelect);
			},
			aActivateSV({ commit },  sv) {
				commit("mActiveSV", sv);
			},
			aLoadConfig({ commit },  cfg) {
				cfg && commit("mLoadConfig", cfg);
			},
			aReLoadOrigin({ commit },  org) {
				org && commit("mReLoadOrigin", org);
			},
			aReLoadBreadCrumbs({ commit },  bc) {
				bc && commit("mReLoadBreadCrumbs", bc);
			},
			aLoadTemplateRuVersion({ commit },  data) {
				data && commit("mLoadTemplateRuVersion", data);
			},

			aSearchPubs({commit, state},  {baseURL, params}) {
				// console.log(`>>> params: ${JSON.stringify(params, '', 4)}`);
				// console.log(`>>> baseURL: ${baseURL}`);
				axi.defaults.baseURL = baseURL
				return axi.post(`${state.config.prefixURL}api/searchPubs`, params)
					.then((res) => {
						if (res.data.status == 'ok') {
							commit('mSearchPubs', res.data);
						} else {
							commit('mError', [res.data.result, 'aSearchPubs-b']);
						}
					})
					.catch(err => {
						Object.keys(err).length > 0 && commit('mError', [err, 'aSearchPubs-a']);
					})
			},
			aUrlById({commit, state}, {params, baseURL}) {
				console.log(`>>> aUrlById params: ${JSON.stringify(params)}, baseURL: ${baseURL} `);
				
				axi.defaults.baseURL = baseURL;
				return axi.post(`${state.config.prefixURL}api/getUrlById`, params)
				.then((res) => {
					console.log(`>>> aUrlById res: ${JSON.stringify(res)} `);
						if (res.data.status == 'ok') {
							commit('mUrlById', res.data.result)
						}
					})
					.catch(err => {
						commit('mError', [err, 'aUrlById'])
					})
			},
			aWidgetParamsShow({commit, state}, {baseURL, params}) {
				axi.defaults.baseURL = baseURL;
				return axi.post(`${state.config.prefixURL}api/getWidgetParamsShow`, params)
				.then((res) => {
					// console.log(`>>> aWidgetParamsShow res: ${JSON.stringify(res)} `);
						if (res.data.status == 'ok') {
							commit('mWidgetParamsShow', res.data.result)
						} 
					})
					.catch(err => {
						commit('mError', [err, 'aUrlById'])
					})
			},
		},

// ---------------------------------------------------------------------------------------------

		mutations: {
			mLoadBook(state, data) {
				// console.log(`>>> mLoadBook: ${JSON.stringify(data, '', 4)}`);
				const clearRelated = [];
				for (const item of data.related ? data.related : []) {
					if (item) {
						clearRelated.push(item);
					}
				}
				data.related = clearRelated;
				state.book = data;
			},
			mLoadArticle(state, data) {
				const clearRelated = []
				for (const item of data.related ? data.related : []) {
					if (item) {
						clearRelated.push(item)
					}
				}
				data.related = clearRelated
				state.article = data
			},
			mLoadChapter(state, data) {
				// console.log(`>>> mLoadChapter: ${JSON.stringify(data, '', 4)}`)
				const clearRelated = []
				for (const item of data.related ? data.related : []) {
					if (item) {
						clearRelated.push(item)
					}
				}
				data.related = clearRelated
				state.chapter = data
			},
			mLoadPreprint(state, data) {
				// console.log(`mLoadPreprint: ${JSON.stringify(data, '',4)}`)
				const clearRelated = []
				for (const item of data.related ? data.related : []) {
					if (item) {
						clearRelated.push(item)
					}
				}
				data.related = clearRelated
				state.preprint = data
			},
			mLoadPublsList(state, data) {
				// console.log(`mLoadPublsList: ${JSON.stringify(data.bookList, '',4)}`)
				state.bookList = data.bookList.items
				state.articleList = data.articleList.items
				state.chapterList = data.chapterList.items
				state.preprintList = data.preprintList.items
			},

			mLoadCopyrightRuleData(state, data) {
				state.copyrightRule = data[0];
				// console.log(`>>mLoadCopyrightRuleData: ${JSON.stringify(state.copyrightRule, '',4)}`);
			},

			mLoadTemplateData(state, data) {
				// console.log(`>>mLoadTemplateData.data: ${JSON.stringify(data, '',4)}`)
				state.templateRuVersion = data[0].origin && data[0].origin.language ==='en'? false : true;
				state.templateCampus = data[0].campus ? data[0].campus : [];
				// state.templateBreadCrumbs = data[0].breadCrumbs ? data[0].breadCrumbs : [];
				state.templateNavigationFooter = data[0].navigation_footer ? data[0].navigation_footer : {};
				state.templateLinks = data[0].links ? data[0].links : {};
				state.templateTopHseName = data[0].topHseName ? data[0].topHseName : {};
				state.templateCopyrightFooter = data[0].copyright_footer ? data[0].copyright_footer : {};
				state.templateT = data[0].t ? data[0].t : {};
				state.templateTopMenu = data[0].topmenu ? data[0].topmenu : [];
				// sidebar
				state.templateDevLine = data[0].devLine ? data[0].devLine : [];
				state.templateYears = data[0].years ? data[0].years : [];
				state.templateNews = data[0].news ? data[0].news : [];
				state.templateResearchTarget = data[0].researchTarget ? data[0].researchTarget : [];

				// console.log(`>>mLoadTemplateData.templateOrigin: ${JSON.stringify(state.templateT, '',4)}`)

				if (data[1] && data[1].selectedRublic) {
					for (const itm of state.templateResearchTarget) {
						if (itm.id === data[1].selectedRublic) {
							itm.selected = true;
							break;
						}
						if (itm.children.length) {
							for (const child of itm.children) {
								if (child.id === data[1].selectedRublic) {
									child.selected = true;
									break;
								}
							}
						}
					}
				};

				const prefixURLLang = `${!state.templateRuVersion ? '/en' : '' }${state.config.prefixURL}`;
				state.templateLogo = {href: prefixURLLang, title: 'publications'};
				state.templatePrimaryMenu = [
					{id:0, href:`${prefixURLLang}books/`, title: 'books', cls: 'navigation__item pubs-primary_menu__item'},
					{id:1, href:`${prefixURLLang}articles/`, title: 'articles', cls: 'navigation__item pubs-primary_menu__item'},
					{id:2, href:`${prefixURLLang}chapters/`, title: 'chapters', cls: 'navigation__item pubs-primary_menu__item'},
					{id:3, href:`${prefixURLLang}preprints/`, title: 'preprints', cls: 'navigation__item pubs-primary_menu__item'},
				];
				state.templateSecondaryMenu = data[0].secondaryMenu.map((itm, index) => {
					return {
						id: index,
						href: itm.url.split("/").includes("https:") ? itm.url : prefixURLLang + itm.url,
						title: itm.title,
						showItem: !itm.hide,
						cls: 'navigation__item pubs-secondary_menu__item'
					}
				});
				state.templateSV = {
						svSize: {
							options: [
								{id: '1', dataType:'size', title: 'A', dataValue:'normal', class:'sv-control__item sv-control__item--s_normal', active: true},
								{id: '2', dataType:'size', title: 'A', dataValue:'medium', class:'sv-control__item sv-control__item--s_medium', active: false},
								{id: '3', dataType:'size', title: 'A', dataValue:'large', class:'sv-control__item sv-control__item--s_large', active: false},
							],
							value: null,
							label: 'Размер',
						},
						svSpacing:{
							options: [
								{id: '1', dataType:'spacing', title: 'АБВ', dataValue:'normal', class:'sv-control__item sv-control__item--ls_normal', active: true},
								{id: '2', dataType:'spacing', title: 'АБВ', dataValue:'medium', class:'sv-control__item sv-control__item--ls_medium', active: false},
								{id: '3', dataType:'spacing', title: 'АБВ', dataValue:'large', class:'sv-control__item sv-control__item--ls_large', active: false},
							],
							value: null,
							label: 'Интервал',
						},
						svContrast:{
							options: [
								{id: '1', dataType:'contrast', title: 'A', dataValue:'normal', class:'sv-control__item sv-control__item---color1', active: true},
								{id: '2', dataType:'contrast', title: 'A', dataValue:'invert', class:'sv-control__item sv-control__item---color2', active: false},
								{id: '3', dataType:'contrast', title: 'A', dataValue:'blue', class:'sv-control__item sv-control__item---color3', active: false},
								{id: '4', dataType:'contrast', title: 'A', dataValue:'beige', class:'sv-control__item sv-control__item---color4', active: false},
								{id: '5', dataType:'contrast', title: 'A', dataValue:'brown', class:'sv-control__item sv-control__item---color5', active: false},
							],
							value: null,
							label: 'Схема',
						},
						svImage:{
							options:[
								{id: '1', dataType:'image', title: '', dataValue:'on', class:'sv-control__item sv-control__item--image_on', active: true},
								{id: '2', dataType:'image', title: '', dataValue:'off', class:'sv-control__item sv-control__item--image_on', active: false},
							],
							value: null,
							label: 'Картинки',
						},
				};
			},
			mChangeSelectPrimaryMenu(state, idSelect) {
				if(state && state.templatePrimaryMenu){
					for (let index = 0; index < state.templatePrimaryMenu.length; index++) {
						const el = state.templatePrimaryMenu[index];
						el.cls = el.id === idSelect
							? 'navigation__item pubs-primary_menu__item pubs-primary_menu__item--selected'
							: 'navigation__item pubs-primary_menu__item'
					}
				}
			},
			mChangeSelectSecongaryMenu(state, idSelect) {
				if (state && state.templateSecondaryMenu) {
					for (let index = 0; index < state.templateSecondaryMenu.length; index++) {
						const el = state.templateSecondaryMenu[index];
						el.cls = el.id === idSelect
							? 'navigation__item pubs-secondary_menu__item' // pubs-secondary_menu__item--selected'
							: 'navigation__item pubs-secondary_menu__item'
					}
				}
			},
			mActiveSV(state, sv) {
				if(sv.dataType === 'size') {
					state.templateSV.svSize.options.map((opt) =>	opt.active = opt.id === sv.id)
				} else if(sv.dataType === 'spacing') {
					state.templateSV.svSpacing.options.map((opt) =>	opt.active = opt.id === sv.id)
				} else if(sv.dataType === 'contrast') {
					state.templateSV.svContrast.options.map((opt) =>	opt.active = opt.id === sv.id)
				}else if(sv.dataType === 'image') {
					state.templateSV.svImage.options.map((opt) =>	opt.active = opt.id === sv.id)
				}
			},
			mLoadConfig(state, data) {
				if (data.config) {
					state.config = data.config
					state.useTemplate = Boolean(data.config?.wotTemplate) === false ? 1 : 2;
					if(data.config.lang) {
						state.templateRuVersion = data.config.lang === 'ru' ? true : false;
					}
				}
			},
			mReLoadOrigin(state, data) {
				if (data.origin)
					state.templateOrigin = data.origin;
			},
			mReLoadBreadCrumbs(state, data) {
				if (data.breadCrumbs)
					state.templateBreadCrumbs = data.breadCrumbs;
			},

			mLoadTemplateRuVersion(state, data) {
				if (data && data.lang) {
					state.templateRuVersion = data.lang === 'ru' ? true : false;
				}
			},

			mSearchPubs(state, data) {
				state.itemList = data.result.items || [];
				state.totalFoundPubls = data.result.total || 0;
				state.cachKey = data.cachKey || '';
				state.totalFoundPages = data.result.total ? Math.ceil(data.result.total / 21) : 0;
				state.pubsPersonGroupType = data.result.groupType || 0;
				state.pubsPersonSort = data.result.pubsPersonSort || 0;
				state.admComment = clearHTML(data.result.admComment);
				// console.log(`>>> admComment:`);
				// console.log(data.result.admComment);
				// console.log(`>>> admComment.clearHTML:`);
				// console.log(state.admComment);
			},

			mUrlById(state, data) {
				state.urlById = data
			},
			mWidgetParamsShow(state, data) {
				// console.log(`>>> mWidgetParamsShow data: ${JSON.stringify(data)} `);
				state.widgetOperParams = data
			},


			mError(state, data) {
				state.error = data[0]
				console.log(`[${data[1]}] mError:  ${JSON.stringify(data[0],'',4)}`)
			},
		},
		modules: {},
		strict: process.env.DEV
    })
    return Store;
}
export default createStore;