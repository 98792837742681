<template>
	<div class="ssr-input-auto">
		<label v-show="label" :for="inputId" class="filter__label">{{label}} </label>
		<div class="ssr-input-auto__container">
				<!-- class="ssr-input-auto__field" -->
				<!-- :autocomplete="Math.random()" -->
			<input type="text" 
				:id="inputId"
				:class="inputClass" 
				v-bind="$attrs"
				v-on:keydown="onKeyDown"
				v-on:blur="hideItems"
				v-on:focus="showItems = true"
				v-model="query"
				autocomplete="off"
				:placeholder="placeholder"
			>
			<div :class="containerInputClass" >
				<ul class="items" v-show="items && items.length > 0 && showItems === true">
					<li class="item"
						:key="index"
						v-for="(item, index) in items"
						@click.prevent="selectItem(index)"
						v-bind:class="{'is-active': index === activeItemIndex }">
						<slot name="item"
							:item="item">
						{{item}}
						</slot>
					</li>
				</ul>
			</div>
		</div>

		<div v-if="checkboxLabel" :class="checkboxContainerClass">
			<label v-if="checkboxLabel !== 'none'" class="filter">
				<input
					class="filter__input filter__input--checkbox"
					type="checkbox"
					v-model="checkboxStatus"
				>
				<span class="filter__label">{{checkboxLabel !== 'none' ? checkboxLabel : ''}}</span>
			</label>
		</div>

	</div>
</template>
<script>
import debounce from 'debounce'

export default {
	inheritAttributes: true,
	name: 'SSRInputAuto',
	props: {
		debounce: {
			type: Number,
			required: true,
			default: 0,
		},
		placeholder: {
			type: String,
			required: false,
			default: "...",
		},
		inputClass: {
			type: String,
			required: false,
			default: "search-input",
		},
		containerInputClass: {
			type: String,
			required: false,
			default: "container-input",
		},
		checkboxLabel: {
			type: String,
			required: false,
			default: "",
		},
		checkboxContainerClass: {
			type: String,
			required: false,
			default: "search-input",
		},
		defaultCkecked: {
			type: Boolean,
			required: false,
			default: false,
		},

		onSearch: {
			type: Function,
			required: true
		},
		onItemSelected: {
			type: Function
		},
		value: {
			type: String,
			required: false,
			default: ''
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		config: {
            type: Object,
            required: false,
            default: () => {},
		},

	},
	data: () => ({
		// defaultOptions: {
			// debounce: 0,
			// placeholder: '',
			// inputClass: 'input',
			// containerInputClass: 'filter__item',
			// checkboxLabel: '',
		// }, 
		// extendedOptions: Object.assign({}, this.defaultOptions, this.options),
		// query: this.value,
		query: '',
		lastSetQuery: null,
		items: [],
		activeItemIndex: -1,
		showItems: false,
		inputId: '',
		checkboxStatus: false,
	}),
	// data () {
	// 	const defaultOptions = {
	// 		debounce: 0,
	// 		placeholder: '',
	// 		inputClass: 'input',
	// 		containerInputClass: 'filter__item',
	// 		checkboxLabel: '',
	// 	}
	// 	const extendedOptions = Object.assign({}, defaultOptions, this.options)
	// 	return {
	// 		extendedOptions,
	// 		query: this.value,
	// 		lastSetQuery: null,
	// 		items: [],
	// 		activeItemIndex: -1,
	// 		showItems: false,
	// 		inputId: '',
	// 		checkboxStatus: this.options.defaultCkecked,
	// 	}
	// },

	beforeMount() {
		this.inputId = `${this.inputClass}_${Math.floor(Math.random()*1000000)}`
		if (this.debounce !== 0) {
			this.onQueryChanged = debounce(this.onQueryChanged, this.debounce)
		}
	},
	mounted() {
		this.query = this.value;
		this.checkboxStatus = this.defaultCkecked;
	},
	watch: {
		'query': function (newValue, oldValue) {
			if (newValue === this.lastSetQuery) {
				this.lastSetQuery = null
				return
			}
			this.onQueryChanged(newValue)
			this.$emit('input', newValue)
		},
		'value': function (newValue, oldValue) {
			this.setInputQuery(newValue)
		}
	},
	methods: {
		onItemSelectedDefault (item) {
		if (typeof item === 'string') {
			this.$emit('input', item)
			this.setInputQuery(item)
			this.showItems = false
			// console.log('change value')
		}
		},
		hideItems () {
			 setTimeout(() => {
				this.showItems = false
			 }, 300)
		},
		showResults () {
			this.showItems = true
		},
		setInputQuery (value) {
			this.lastSetQuery = value
			this.query = value
		},
		onKeyDown (e) {
		this.$emit('keyDown', e.keyCode)
		switch (e.keyCode) {
			case 40:
				this.highlightItem('down')
				e.preventDefault()
				break
			case 38:
				this.highlightItem('up')
				e.preventDefault()
				break
			case 13:
				this.selectItem()
				e.preventDefault()
				break
			case 27:
				this.showItems = false
				e.preventDefault()
			break
			default:
				return true
		}
		},
		selectItem (index) {
			let item = null
			if (typeof index === 'undefined') {
				if (this.activeItemIndex === -1) {
					return
				}
				item = this.items[this.activeItemIndex]
			} else {
				item = this.items[index]
			}

			if (!item) {
				return
			}
			if (this.onItemSelected) {
				this.onItemSelected(item, this.checkboxStatus)
			} else {
				this.onItemSelectedDefault(item, this.checkboxStatus )
			}
			this.hideItems()
		},
		highlightItem (direction) {
			if (this.items.length === 0) {
				return
			}
			let selectedIndex = this.items.findIndex((item, index) => {
				return index === this.activeItemIndex
			})
			if (selectedIndex === -1) {
				// nothing selected
				if (direction === 'down') {
					selectedIndex = 0
				} else {
					selectedIndex = this.items.length - 1
				}
			} else {
				this.activeIndexItem = 0
				if (direction === 'down') {
					selectedIndex++
					if (selectedIndex === this.items.length) {
						selectedIndex = 0
					}
				} else {
					selectedIndex--
					if (selectedIndex < 0) {
						selectedIndex = this.items.length - 1
					}
				}
			}
			this.activeItemIndex = selectedIndex
		},
		setItems (items) {
			this.items = items
			this.activeItemIndex = -1
			this.showItems = true
		},
		onQueryChanged (value) {
			const result = this.onSearch(value, this.checkboxStatus)
			this.items = []
			if (typeof result === 'undefined' || typeof result === 'boolean' || result === null) {
				return
			}
			if (result instanceof Array) {
				this.setItems(result)
			} else if (typeof result.then === 'function') {
				result.then(items => {
				this.setItems(items)
				})
			}
		}
	}
}
</script>
